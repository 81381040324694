<template>
    <div>
        <Divider dashed ><span class="divider-text">媒体采购</span></Divider>
        <h4 class="p-b-5" style="margin-top: -10px;">发布档期</h4>
        <Row class="p-b-5">
            <i-col span="24">
                <div v-for="(schedule,index) in orderBean.orderScheduleList" :key="index" class="p-l-20 p-r-20 m-b-2"
                    :class="checkedschedules.includes((schedule.startDate+'_'+schedule.endDate))?'workplatform-radio-button-active':'workplatform-radio-button'"
                    @click="changeCheckedschedules(schedule.startDate,schedule.endDate)">{{getScheduleInfo(schedule.startDate,schedule.endDate)}}</div>
                <a class="m-l-10" @click="modalEditSchedule=true">编辑档期</a>
            </i-col>
        </Row>
        <h4 class="p-b-5">资源类型</h4>
        <Row class="p-b-5">
            <i-col span="24">
                <div class="p-l-10 p-r-10 m-b-2" :class="(item.id === checkMediaId)?'workplatform-radio-button-active':'workplatform-radio-button'"
                  v-for="(item,index) in useMediaList" :key="index" @click="changeMedia(item)">{{item.name}}</div>
            </i-col>
        </Row>
        <h4 class="p-b-5">
          发布{{mediaTypeName}} <a class="m-l-10" @click="handleCheckAllLines">全选{{mediaTypeName}}</a>
        </h4>
        <Row class="p-b-5">
            <i-col span="24">
                <div v-for="(line,index) in lines"  :key="index" @click="changeCheckLines(line.value)"  class="m-b-2"
                    :class="checkedLines.includes(line.value)?'workplatform-radio-button-active':'workplatform-radio-button'">{{line.label}}</div>
            </i-col>
        </Row>

        <template v-if="checkMediaId!==7">
          <h4 class="p-b-5">可用产品</h4>
          <Row class="p-b-5">
              <i-col span="24">
                  <div v-for="product in productList" :key="product.id" class="p-l-10 p-1-10 m-b-2" @click="changeCheckedProduct(product)"
                      :class="currentProductId===product.id?'workplatform-radio-button-active':'workplatform-radio-button'">{{product.name}}</div>
              </i-col>
          </Row>

          <template v-if="resourcetypeList.length>1">
            <h4 class="p-b-5">资源类型</h4>
            <Row class="p-b-5">
                <i-col span="24">
                    <div v-for="resourcetype in resourcetypeList" :key="resourcetype.id" class="p-l-10 p-1-10 m-b-2" @click="changeCheckedResourceType(resourcetype.id)"
                        :class="currentResourceType.includes(resourcetype.id)?'workplatform-radio-button-active':'workplatform-radio-button'">{{resourcetype.name}}</div>
                </i-col>
            </Row>
          </template>
        </template>

        <component ref="leftComponent" :is="currentComponent" :orderBean="orderBean"
            @on-update-order="handleUpdatePurchaseItem" @on-updated-map="handleUpdateMap"/>

        <editSchedule :orderBean="orderBean" :isShow.sync="modalEditSchedule" @on-update-order="handelUpdateOrderSchedule"/>
    </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import editSchedule from '@/components/order/common/EditSchedule'

import { getCategoryLists, assetList } from '@/api/product/category'
import { GetCurrentSchedule } from '@/utils/dateFormat'
import { getAllProduct } from '@/api/product/product'

export default {
  mixins: [sysMixins],
  components: {
    editSchedule,
    // 传统媒体
    pMetroComponent: () => import('@/components/order/purchaseComponets/pMetro'),
    pTrainComponent: () => import('@/components/order/purchaseComponets/pTrain'),
    // 电子媒体
    pElecChooseComponent: () => import('@/components/order/purchaseComponets/pElecChoose'),
    pElectronicComponent: () => import('@/components/order/purchaseComponets/pElectronic'),
    pPISComponent: () => import('@/components/order/purchaseComponets/pPIS'),
    // 快速通道
    pQuickComponent: () => import('@/components/order/purchaseComponets/pQuick')
  },
  data () {
    return {
      useMediaList: [], // 可用的媒体类型列表
      checkMediaId: 0, // 当前选中的媒体类型ID

      checkedschedules: [], // 选中的档期
      isChangeSchedule: false,
      modalEditSchedule: false,

      lines: [], // 所有可用线路
      checkedLines: [], // 当前选中线路

      productList: [],
      currentProductId: 0,
      productHasElectric: false,

      resourcetypeList: [], // 当前可用的资源类型清单
      currentResourceType: [],

      currentComponent: '',
      mediaTypeName: this.$store.getters.token.publisherSetting.mediaType === 1 ? '线路' : '区域'
    }
  },
  methods: {
    initPageData () {
      // 设置默认选中档期
      this.checkedschedules = []
      this.orderBean.orderScheduleList.forEach(res => {
        this.checkedschedules.push((res.startDate + '_' + res.endDate))
      })

      this.useMediaList = []
      // 获取当前媒介主（客户）开启的可售媒体类型
      getCategoryLists({ brandId: this.orderBean.brandId }).then(res => {
        this.useMediaList = res
        // 显示默认的采购组件
        this.changeMedia(this.useMediaList[0])
      })
    },
    changeMedia (media) {
      this.checkMediaId = media.id

      this.currentComponent = ''
      this.$store.commit('set_order_categoryId', media.id)
      this.loadPublishLines()
    },
    getScheduleInfo (startDate, endDate) {
      return GetCurrentSchedule(startDate, endDate)
    },
    changeCheckedschedules (startDate, endDate) {
      const value = startDate + '_' + endDate
      if (this.checkedschedules.includes(value)) {
        const index = this.checkedschedules.indexOf(value)
        if (this.checkedschedules.length === 1) {
          this.$Notice.warning({ desc: '至少需要选择一个发布档期' })
        } else {
          this.checkedschedules.splice(index, 1)
        }
      } else {
        this.checkedschedules.push(value)
      }

      this.loadComponentData()
    },
    loadPublishLines () {
      // 加载可用的城市、线路
      this.checkedLines = []
      const assetquery = {
        categoryId: this.checkMediaId,
        brandId: this.orderBean.brandId
      }
      assetList(assetquery).then(res => {
        this.lines = res.map(x => {
          return { value: x.id, label: x.name, rimAssetIds: x.rimAssetIds }
        })

        if (res.length > 0) {
          this.changeCheckLines(res[0].id)
        }
      })
    },
    changeCheckLines (lineId) {
      if (this.checkedLines.includes(lineId)) {
        const index = this.checkedLines.indexOf(lineId)
        if (this.checkedLines.length === 1) {
          this.$Notice.warning({ desc: '至少需要一个' + this.mediaTypeName })
        } else {
          this.checkedLines.splice(index, 1)
        }
      } else {
        this.checkedLines.push(lineId)
      }

      this.loadProductList()
    },
    loadProductList () {
      const productQuery = {
        categoryId: this.checkMediaId,
        brandId: this.orderBean.brandId,
        assetIds: JSON.stringify(this.checkedLines)
      }
      getAllProduct(productQuery).then(res => {
        this.productList = res
        if (this.checkMediaId === 4) {
          this.productList.unshift({ id: -1, type: 2, name: '全部' })
        }

        // 判断是否包含当前产品，如果是，那么不调整
        const chooseProduct = res.find(x => x.id === this.currentProductId)
        if (chooseProduct) {
          this.changeCheckedProduct(chooseProduct)
        } else {
          this.changeCheckedProduct(res[0])
        }
      })
    },
    changeCheckedProduct (product) {
      this.currentProductId = product.id
      if (product.resourcetypeList && product.resourcetypeList.length > 0) {
        this.resourcetypeList = product.resourcetypeList
        // 全选所有产品
        this.currentResourceType = this.resourcetypeList.map(x => x.id)
      } else {
        this.resourcetypeList = []
        this.currentResourceType = []
      }

      this.currentComponent = ''
      // 开始载入媒体采购组件
      if (product.type === 1) {
        this.currentComponent = 'pMetroComponent'
      } else if (product.type === 2 || product.type === 19) { // 主题媒体和电子屏组合
        this.currentComponent = 'pQuickComponent'
      } else if (product.type === 3) { // 列车
        this.currentComponent = 'pTrainComponent'
      } else if (product.type === 6) { // 套装
        this.currentComponent = 'pQuickComponent'
      } else if (product.type === 7) { // PIS屏
        this.currentComponent = 'pPISComponent'
      } else if (product.type === 14) { // 点播屏
        this.currentComponent = 'pElecChooseComponent'
      } else if (product.type === 18) { // 电子屏
        this.currentComponent = 'pElectronicComponent'
      } else if (product.type === 5) { // 候车亭大牌
        this.currentComponent = 'pMetroComponent'
      }

      this.productHasElectric = product.hasElectric

      this.$nextTick(() => {
        this.loadComponentData()
      })
    },
    changeCheckedResourceType (resourcetypeId) {
      if (this.currentResourceType.includes(resourcetypeId)) {
        const index = this.currentResourceType.indexOf(resourcetypeId)
        this.currentResourceType.splice(index, 1)
      } else {
        this.currentResourceType.push(resourcetypeId)
      }

      this.loadComponentData()
    },
    loadComponentData () {
      if (this.$refs.leftComponent) {
        const searchBean = {
          orderId: this.orderBean.id,
          schedules: this.schedules,
          assetIds: this.checkedLines,
          categoryId: this.checkMediaId,
          productId: this.currentProductId,
          resourcetypeIds: JSON.stringify(this.currentResourceType),

          orderScheduleList: this.orderBean.orderScheduleList,
          allowProductList: this.productList, // 全部允许的产品清单
          allowLines: this.lines // 全部的线路
        }
        this.$refs.leftComponent.initPageData(searchBean, this.productHasElectric)
      } else { // 隔500毫秒后重新请求
        setTimeout(() => {
          this.loadComponentData()
        }, 500)
      }
    },
    handleUpdatePurchaseItem (defaultProduct) {
      if (defaultProduct) {
        this.$store.commit('set_order_defaultProduct', defaultProduct)
      }

      this.$store.commit('set_order_update_purchase', new Date())
    },
    handleUpdateMap (type, params) {
      if (params.length === 0) { // 清空页面
        this.$store.commit('set_order_stations', [])
        this.$store.commit('set_order_update_map', new Date())
      } else {
        // 可能存在虚拟现实，比如北京复八线，其实是地铁1号线。  也可能存在小微，包含多条线路包含在一个虚拟包
        const showAssetIds = []
        let flagAssetIds = []
        this.lines.forEach(element => {
          if (params.assetIds.includes(element.value)) {
            flagAssetIds = element.rimAssetIds.split(',')
            flagAssetIds.forEach(rimAssetId => {
              // 可能存在重复数据，比如北京地铁一号线、北京地铁复八线。都是指向地铁一号线
              if (!showAssetIds.includes(parseInt(rimAssetId))) {
                showAssetIds.push(parseInt(rimAssetId))
              }
            })
          }
        })
        this.$store.commit('set_order_show_assets', showAssetIds)

        this.$store.commit('set_order_map_type', type)
        this.$store.commit('set_order_check_schedule', params.checkedSchedules)
        this.$store.commit('set_order_stations', params.data)
        this.$store.commit('set_order_search_condition', params.condition)
        this.$store.commit('set_order_warning_stations', params.warningStations ? params.warningStations : [])

        this.$store.commit('set_order_update_map', new Date())
      }
    },
    handelUpdateOrderSchedule () { // 更新订单档期
      const query = { orderId: this.orderBean.id }
      this.$store.dispatch('getOrderBaseInfo', query)
      this.isChangeSchedule = true
    },
    handleCheckAllLines () {
      this.lines.forEach(element => {
        if (!this.checkedLines.includes(element.value)) {
          this.checkedLines.push(element.value)
        }
      })
      this.loadProductList()
    }
  },
  computed: {
    orderBean () {
      return this.$store.state.order.orderBean
    },
    leftReload () {
      return this.$store.state.order.leftReload
    },
    // 获取选择的订单档期
    schedules: function () {
      const schedules = []
      this.checkedschedules.forEach(item => {
        const shcedule = item.split('_')
        schedules.push({ startDate: shcedule[0], endDate: shcedule[1] })
      })
      return schedules
    }
  },
  watch: {
    orderBean () {
      this.initPageData()
      if (this.isChangeSchedule) {
        this.loadComponentData()
        this.isChangeSchedule = false
      }
    },
    leftReload () {
      if (this.$refs.leftComponent) {
        this.$refs.leftComponent.reload()
      }
    }
  }
}
</script>
